<template>
  <ContainerBasic class="mt-md md:mt-xl" container-type="FULL_WIDTH">
    <!-- Tablet / Desktop-->
    <div
      v-if="!isMobile"
      class="h-[500px] overflow-hidden items-end bg-center bg-cover"
      :style="`background-image: url(${image.url})`"
    >
      <div
        v-if="image.copyright !== null"
        class="max-w-[180px] absolute bottom-[45px] md:bottom-[60px] right-[15px] md:right-[30px] text-right"
      >
        <span
          class="text-sm italic font-light text-white drop-shadow-xs font-roboto leading-xs"
          >&copy; {{ image.copyright }}</span
        >
      </div>
      <ContainerBasic
        container-type="PAGE"
        class="w-full h-full"
        :inner-classes="
          blockData.orientation === 'left'
            ? 'justify-items-start grid'
            : 'justify-items-end grid'
        "
      >
        <div
          class="relative p-sm desktop:md:px-lg touch:md:px-2xl desktop:lg:px-2xl md:py-md w-[450px] touch:md:w-[690px] lg:w-[690px] text-sm touch:md:text-md lg:text-md bg-activesection-base text-white flex"
          :class="[classesByShape]"
          :style="styles"
        >
          <div
            class="grid content-center w-full"
            :class="{
              'h-[95%] touch:md:h-[65%] lg:h-[65%]':
                blockData.shape !== 'rectangle',
            }"
          >
            <div class="min-h-[40px] touch:md:min-h-[80px] lg:min-h-[80px]">
              <atom-circle-icon
                v-if="blockData.icon"
                class="mb-sm touch:md:mb-md lg:mb-md"
                :icon="blockData.icon"
                :small="!isDesktopView"
                :background-color="blockData.bgColor.value"
                :icon-color="blockData.textColor.value"
              ></atom-circle-icon>
            </div>
            <div
              :class="{
                'top-md': blockData.shape === 'rectangle',
              }"
            >
              <Headline
                v-if="blockData.headline"
                :headline="blockData.headline"
                :level="blockData.headlineSize"
                :text-color="
                  blockData.textColor.style.length
                    ? blockData.textColor.style
                    : 'text-white'
                "
                class="mb-sm fill-activesection-font-base stroke-activesection-font-base"
                :has-decoration="true"
              />
              <WYSIWYGContentRaw
                :content="blockData.text"
                inner-classes="wysi-small-text"
              ></WYSIWYGContentRaw>

              <div v-if="blockData?.cta" class="mt-auto pt-sm">
                <BlockCallToAction
                  :block-data="blockData.cta"
                ></BlockCallToAction>
              </div>
            </div>
          </div>
        </div>
      </ContainerBasic>
    </div>

    <!-- Mobile -->
    <div
      v-if="isMobile"
      class="relative flex flex-col overflow-hidden text-white mx-sm bg-activesection-base"
      :style="styles"
    >
      <div class="min-h-[84px]">
        <ImageDetailed
          v-if="blockData.image"
          :image-data="blockData.image"
          :show-meta="false"
        />
      </div>
      <div class="after:bg-activesection-base"></div>
      <div class="!pt-0 p-sm md:p-md">
        <atom-circle-icon
          v-if="blockData.icon"
          :icon="blockData.icon"
          class="left-[50%] translate-x-[-50%] translate-y-[-50%] !absolute"
          :icon-color="blockData.textColor.value"
          :background-color="blockData.bgColor.value"
        ></atom-circle-icon>

        <div :class="blockData.icon ? 'mt-[60px]' : 'mt-[30px]'">
          <Headline
            v-if="blockData.headline"
            :headline="blockData.headline"
            :level="blockData.headlineSize"
            container-classes="!mb-sm min-h-[32px]"
            :text-color="blockData.textColor.style.length ? '#346e73' : 'white'"
            class="fill-activesection-font-base stroke-activesection-font-base"
            :has-decoration="true"
          />
          <WYSIWYGContentRaw
            :content="blockData.text"
            inner-classes="px-0"
          ></WYSIWYGContentRaw>
          <div v-if="blockData?.cta" class="mt-auto pt-sm">
            <BlockCallToAction :block-data="blockData.cta"></BlockCallToAction>
          </div>
        </div>
      </div>
    </div>
  </ContainerBasic>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import ImageDetailed from '@/components/components/cms/components/image-detailed.vue';
import ContainerBasic from '@/components/components/container-basic.vue';
import AtomCircleIcon from '@/components/atom/circle-icon.vue';
import BlockCallToAction from '~~/src/components/block/cta/vdv/CallToAction.vue';
import { Headline, WYSIWYGContentRaw } from '~/complib';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { HighlightData } from '../blockType';

const isMobile = inject(mq_breakpointIsMobile);

const isDesktopView = useIsDesktopView();

const props = defineProps({
  blockData: {
    type: Object as PropType<HighlightData>,
    required: true,
    default: {} as HighlightData,
  },
});

const image = computed(() =>
  props.blockData.image
    ? isMobile.value
      ? props.blockData.image.mobile
      : props.blockData.image.desktop
    : undefined,
);

const styles = ref('');

onMounted(() => {
  styles.value =
    props.blockData.bgColor.style + ' ' + props.blockData.textColor.style;
});

const classesByShape = computed(() => {
  if (props.blockData.shape === 'rectangle') {
    return 'h-full';
  }

  return 'h-[450px] touch:md:h-[690px] lg:h-[690px] top-[30px] rounded-full';
});
</script>
